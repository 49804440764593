<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          class="demo-form-inline"
          :rules="rule"
          :model="formInline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <!-- <el-form-item label="报警编号">
            <el-input  placeholder="报警编号" maxlength="30" v-model="formInline.alarmCode"></el-input>
          </el-form-item> -->
          <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber" ref="plateNumber">
            <el-autocomplete
              class="inline-input"
              v-model="formInline.plateNumber"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入内容"
              value-key="plateNumber"
              :trigger-on-focus="false"
              @select="handleSelect"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Alarm_type')">
            <el-select v-model.trim="formInline.type" filterable>
              <el-option label="全部" value=""></el-option>
              <el-option label="超长停车" value="0"></el-option>
              <el-option label="套牌车" value="1"></el-option>
            </el-select>
          </el-form-item>
          <!--<el-form-item  label="所在道路" prop="parkId">-->
          <!--<el-select v-model.trim="formInline.parkId" filterable  size="15">-->
          <!--<el-option label="全部" value=""></el-option>-->
          <!--<el-option :label="value.parkName" :value="value.parkId" :key="value.parkId" v-for="value in tenantList"></el-option>-->
          <!--</el-select>-->
          <!--</el-form-item>-->
          <el-form-item label="所在道路">
            <my-component
              ref="parkInput"
              :operationId="formInline.operationId"
              @valueChange="completeValue"
            >
            </my-component>
          </el-form-item>
          <!--<el-form-item :label="$t('searchModule.Alarm_time')">-->
          <!--<el-date-picker type="datetime" v-model="formInline.date" placeholder="选择日期" > </el-date-picker>-->
          <!--</el-form-item>-->

              <el-form-item :label="$t('searchModule.Alarm_time')">
                <el-date-picker
                  :editable="false"
                  v-model.trim="dateRange"
                  type="daterange"
                  placeholder="请选择时间范围"
                  @change="dateFmt(dateRange)"
                >
                </el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
              <!--<el-form-item label="报警状态">-->
              <!--<el-input placeholder="报警状态"></el-input>-->
              <!--</el-form-item>-->
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searParkRecordList();
                "
                v-if="$route.meta.authority.button.query"
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <!--<el-button type="primary" icon="el-icon-search" @click="lookParkRecordList()" :loading="loading">查看</el-button>-->
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center"></el-table-column>
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column
            align="center"
            label="操作"
            width="80"
            type="index"
            v-if="$route.meta.authority.button.detail"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="primary" size="small"> 操作 </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a" v-if="$route.meta.authority.button.detail"
                    >查看</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block" style="float: right">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dateFormat } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
export default {
  data() {
    let checkParkNum = (rule, value, callback) => {
      let len = value.length;
      if (!value) {
        callback();
      } else if (len < 3) {
        callback(new Error("至少输入三个字符"));
      } else if (len > 3 && len <= 11) {
        callback();
      } else if (len > 11) {
        callback(new Error("最多输入十一个字符"));
      }
    };
    return {
      rule: {
        plateNumber: [
          {
            validator: checkParkNum,
            trigger: "blur",
          },
        ],
      },
      page: 1,
      type: "",
      pageSize: 15,
      //        dateRange: [new Date(startTime), new Date(endTime)],
      dateRange: "",
      total: 0,
      plateNumber: "",
      loading: false,
      tableData: [],
      tenantList: [],
      formInline: {
        parkId: "",
        plateNumber: "",
        // alarmCode: '',
        type: "",
      },
      tableCols: [
        //   {
        //   prop: 'alarmCode',
        //   label: '报警编号',
        //   width: ''
        // },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
        },
        {
          prop: "type",
          label: "报警类型",
          formatter: (cellValue) => {
            if (cellValue.type == 0) {
              return "超长停车";
            } else {
              return "套牌车";
            }
          },
        },
        {
          prop: "parkName",
          label: "所在道路",
          width: "",
        },
        {
          prop: "reportTime",
          label: "报警时间",
          width: "",
        },
      ],
    };
  },
  components: {
    myComponent,
  },
  methods: {
    dateFmt(data) {
      if (data && data.length === 2) {
        let startTime = dateFormat(data[0], "yyyy-MM-dd");
        let endTime = dateFormat(data[1], "yyyy-MM-dd");
        this.formInline.startTime = startTime;
        this.formInline.endTime = endTime;
      } else {
        this.formInline.startTime = "";
        this.formInline.endTime = "";
      }
    },
    handleCommand(com, data) {
      if (data.type == 0) {
        this.$router.push({ path: "/carpoliceDeck", query: data });
      } else {
        this.$router.push({ path: "/carpoliceLong", query: data });
      }
      // this.$router.push({ path: "/carpoliceLong", query: data });
      //        let url = '/acb/2.0/carAlarm/detail/' + data.alarmId
      //        this.$axios.get(url, {
      //          data: {
      //        }
      //      }).then(res => {
      //          this.loading = false;
      //        if (res.state === 0) {
      //          this.CarAlarmVO = res.value.CarAlarmVO;
      //          if (res.value.CarAlarmVO.type === 0) {
      //            this.$router.push({path: '/carpoliceLong', query: data});
      //          } else {
      //            this.$router.push({path: '/carpoliceCover', query: data})
      //          }
      //        }
      //      })
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    lookParkRecordList() {},
    streetChange() {},
    detailShow(e) {},
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let url = "/acb/2.0/parkRecord/plateNumber/list";
      this.$axios
        .get(url, {
          data: {
            part: queryString,
            size: 100,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            if (res.state == 0) {
              res.value.forEach((v) => {
                v.value = v.plateNumber;
              });
              cb(res.value);
            }
          }
        });
    },
    handleSelect(item) {
      this.formInline.carId = item.carId;
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searParkRecordList();
    },
    /* 所在道路下拉框 */
    getTenantList() {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getParkName", {
          data: {
            page: 0,
            pageSize: 20,
            operationId: "",
            parkName: "",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tenantList = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    searParkRecordList() {
      let url = "/acb/2.0/carAlarm/query";
      if (this.formInline.plateNumber.length > 1 && this.formInline.plateNumber.length < 3) {
        this.$message("至少输入三个字符");
        return false;
      }
      if (this.formInline.plateNumber == "") {
        this.formInline.carId = "";
      }
      this.$axios
        .get(url, {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.total = res.value.total * 1;
            this.tableData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.formInline.parkId = "";
        this.temParkData = {};
      }
    },
  },
  mounted() {
    this.searParkRecordList();
    this.getTenantList();
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus"></style>
